import React, { useState, useEffect, useRef, useCallback } from "react";
import "./ImageCarousel.css";

const ImageCarousel = ({ images }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [progress, setProgress] = useState(0);
	const timerRef = useRef(null);
	const progressRef = useRef(null);

	// Timer to automatically switch images on 10 second interval
	const resetTimers = useCallback(() => {
		// Clear existing timers
		if (timerRef.current) clearInterval(timerRef.current);
		if (progressRef.current) clearInterval(progressRef.current);

		// Restart timers
		setProgress(0);
		progressRef.current = setInterval(() => {
			setProgress((prevProgress) =>
				prevProgress < 100 ? prevProgress + 1 : 100
			);
		}, 100);

		timerRef.current = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
			setProgress(0); // Reset progress when slide changes
		}, 10000);
	}, [images.length]);

	useEffect(() => {
		resetTimers();
		return () => {
			clearInterval(timerRef.current);
			clearInterval(progressRef.current);
		};
	}, [resetTimers]);

	// Reset timers and select image on thumbnail click
	const handleThumbnailClick = (index) => {
		setCurrentIndex(index);
		resetTimers();
	};

	return (
		<div className="carousel-container">
			<div className="main-image">
				<img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
				<div className="progress-bar">
					<div
						className="progress-fill"
						style={{ width: `${progress}%` }}
					></div>
				</div>
			</div>

			<div className="thumbnail-container">
				{images.map((image, index) => (
					<img
						key={index}
						src={image}
						alt={`Thumbnail ${index + 1}`}
						className={`thumbnail ${currentIndex === index ? "active" : ""}`}
						onClick={() => handleThumbnailClick(index)}
					/>
				))}
			</div>
		</div>
	);
};

export default ImageCarousel;
