import React, { useState } from "react";
import "./ContactForm.css";
import { IoSend } from "react-icons/io5";

const ContactForm = () => {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		message: "",
	});
	const [errors, setErrors] = useState({
		name: false,
		email: false,
		message: false,
	});
	const [status, setStatus] = useState(""); // to handle success/error states

	// Simple email validation regex
	const isValidEmail = (email) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
		setErrors({ ...errors, [name]: !value.trim() }); // Update errors
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Check if the form has errors before submission
		if (
			!formData.name.trim() ||
			!formData.email.trim() ||
			!formData.message.trim() ||
			!isValidEmail(formData.email)
		) {
			setErrors({
				name: !formData.name.trim(),
				email: !formData.email.trim() || !isValidEmail(formData.email),
				message: !formData.message.trim(),
			});
			return;
		}

		// Form submission via Formspree
		const formDataToSend = new FormData();
		formDataToSend.append("name", formData.name);
		formDataToSend.append("email", formData.email);
		formDataToSend.append("message", formData.message);

		setStatus("sending");

		try {
			const response = await fetch("https://formspree.io/f/mnnqylnb", {
				method: "POST",
				body: formDataToSend,
				headers: {
					Accept: "application/json",
				},
			});

			if (response.ok) {
				setStatus("success");
				alert(
					`Thank you for contacting us, "${formData.name}"!\nWe will get back to you soon over at ${formData.email}`
				);
				setFormData({ name: "", email: "", message: "" }); // Reset form
				document.getElementById("name").focus(); // Focus on the name field after form reset
			} else {
				setStatus("error");
				alert("There was an error submitting your form. Please try again.");
			}
		} catch (error) {
			setStatus("error");
			alert("There was an error submitting your form. Please try again.");
		}
	};

	return (
		<form className="contact-form" onSubmit={handleSubmit}>
			<label htmlFor="name">
				YOUR NAME
				<input
					type="text"
					id="name"
					name="name"
					value={formData.name}
					onChange={handleChange}
					placeholder="Your first name or pseudonym."
					aria-invalid={errors.name}
					aria-describedby="nameError"
					required
				/>
				{errors.name && (
					<span id="nameError" className="error">
						Name is required.
					</span>
				)}
			</label>

			<label htmlFor="email">
				YOUR EMAIL
				<input
					type="email"
					id="email"
					name="email"
					value={formData.email}
					onChange={handleChange}
					placeholder="Your email"
					aria-invalid={errors.email}
					aria-describedby="emailError"
					required
				/>
				{errors.email && (
					<span id="emailError" className="error">
						{errors.email && !isValidEmail(formData.email)
							? "Invalid email format."
							: "Email is required."}
					</span>
				)}
			</label>

			<label htmlFor="message">
				WHY YOU'RE CONTACTING US
				<textarea
					id="message"
					name="message"
					value={formData.message}
					onChange={handleChange}
					placeholder="Type here."
					aria-invalid={errors.message}
					aria-describedby="messageError"
					required
					style={{ minHeight: "300px", maxHeight: "300px" }}
				/>
				{errors.message && (
					<span id="messageError" className="error">
						Message is required.
					</span>
				)}
			</label>

			<div className="button-wrapper">
				<span className="info-text">
					We'd love to hear from you! Don't forget to give us your contact info
					so we can answer back.
				</span>
				<button type="submit" disabled={status === "sending"}>
					<IoSend className="send-icon" />
					<span className="send-text">
						{status === "sending" ? "SENDING..." : "SEND"}
					</span>
				</button>
			</div>
		</form>
	);
};

export default ContactForm;
