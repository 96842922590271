import React from 'react';
import { FaYoutube, FaDiscord, FaLinkedin, FaLink, FaArtstation, FaSoundcloud, FaInstagram, FaSpotify } from 'react-icons/fa';
import { FaXTwitter, FaBluesky } from "react-icons/fa6";
import { SiLinktree } from "react-icons/si";
import './TeamMember.css';

const iconsMap = {
    linktree: SiLinktree,
    twitter: FaXTwitter,
    bluesky: FaBluesky,
    discord: FaDiscord,
    link: FaLink,
    artstation: FaArtstation,
    soundcloud: FaSoundcloud,
    instagram: FaInstagram,
    linkedin: FaLinkedin,
    youtube: FaYoutube,
    spotify: FaSpotify,
};

const TeamMember = ({ icon, fullname, role, socialLinks }) => {
    return (
        <div className="team-member-card">
            <img src={icon} alt="Profile Icon" className="profile-icon" />
            <h5 className="team-member-name">{fullname}</h5>
            <p className="team-member-role">{role}</p>
            <div className="social-icons">
               {socialLinks.map(({ platform, url }) => {
                    const IconComponent = iconsMap[platform];
                    return (
                        url && (
                              <a 
                                   key={platform} 
                                   href={url} 
                                   target="_blank" 
                                   rel="noopener noreferrer"
                                   classn
                              >
                                   <IconComponent />
                              </a>
                        )
                    );
                })}
            </div>
        </div>
    );
};

export default TeamMember;