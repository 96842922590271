import React from 'react';
import { FaDiscord, FaYoutube, FaSteam } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-top-line"></div>
            
            <div className="footer-content">
                <div className="footer-categories">
                    <div className="footer-category">
                        <h5>Explore</h5>
                        <ul>
                            <li><a href="#games">Our Games</a></li>
                            <li><a href="#company">Our Company</a></li>
                            <li><a href="#team">Our Team</a></li>
                            <li><a href="#contact">Contact Us</a></li>
                        </ul>
                    </div>

                    {/* <div className="footer-category">
                        <h5>Resources</h5>
                        <ul>
                            <li><span className="disabled-link">GitHub</span></li>
                            <li><span className="disabled-link">Press</span></li>
                            <li><span className="disabled-link">Blog</span></li>
                            <li><span className="disabled-link">Support</span></li>
                        </ul>
                    </div> */}

                    <div className="footer-category">
                        <h5>Socials</h5>
                        <ul>
                            <li><a href="https://x.com/NikkF__">X (Twitter)</a></li>
                            <li><a href="https://discord.gg/scpvalravn">Discord</a></li>
                            <li><a href="https://www.youtube.com/@SubtleBravadoStudios">YouTube</a></li>
                            <li><a href="https://store.steampowered.com/app/2707670/SCP_Valravn/">Steam</a></li>
                        </ul>
                    </div>
                </div>

                <div className="footer-icons">
                    <a href="https://x.com/NikkF__" aria-label="Twitter" target="_blank" rel="noopener noreferrer">
                         <FaXTwitter />
                    </a>
                    <a href="https://discord.gg/scpvalravn" aria-label="Discord" target="_blank" rel="noopener noreferrer">
                         <FaDiscord />
                    </a>
                    <a href="https://www.youtube.com/@SubtleBravadoStudios" aria-label="YouTube" target="_blank" rel="noopener noreferrer">
                         <FaYoutube />
                         </a>
                    <a href="https://store.steampowered.com/app/2707670/SCP_Valravn/" aria-label="Steam" target="_blank" rel="noopener noreferrer">
                         <FaSteam />
                    </a>
                </div>

                <div className="footer-copyright">
                    @ 2024, Subtle Bravado Studios
                </div>
            </div>
        </footer>
    );
};

export default Footer;
