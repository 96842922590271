import "react-bootstrap";
import "./App.css";
import Header from "./components/Header.jsx";
import Footer from "./components/Footer.jsx";
import SocialMediaButton from "./components/SocialMediaButton.jsx";
import ImageCarousel from "./components/ImageCarousel.jsx";
import TeamMember from "./components/TeamMember.jsx";
import ContactForm from "./components/ContactForm.jsx";

import home_logo from "./media/home_logo.png";
import home_background from "./media/home_background_video.webm";
import studio_icon from "./media/studio_icon.png";
import game_logo from "./media/game_logo.png";
import screenshot1 from "./media/game_screenshot.png";
import screenshot2 from "./media/game_screenshot2.png";
import screenshot3 from "./media/home_background.png";

import { FaDiscord, FaYoutube, FaSteam, FaPlay, FaAngleDown } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import React, { useState, useEffect } from "react";
import teamData from "./team.json";

function App() {
	// Reading team.json and creating the TeamMember components
	const [teamMembers, setTeamMembers] = useState([]);
	useEffect(() => {
		setTeamMembers(teamData);
	}, []);

	// Scroll-down arrow on home section
	const [scrollOpacity, setScrollOpacity] = useState(1);
	useEffect(() => {
		const handleScroll = () => {
			const scrollTop = window.scrollY;
			const maxScroll = 400; // adjust this value to control when the arrow fully disappears
			const newOpacity = Math.max(1 - scrollTop / maxScroll, 0);
			setScrollOpacity(newOpacity);
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);
	const handleArrowClick = () => {
		const nextSection = document.getElementById("games");
		if (nextSection) {
			nextSection.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<div className="App">
			<header id="header">
				<Header />
			</header>

			<section id="home">
				<video
					autoPlay
					loop
					muted
					id="home-background-video"
					>
					<source src={home_background} type="video/webm" />
				</video>
				<img
					src={home_logo}
					alt=""
					className="home-logo"
				/>
				<h3 
					style={{ paddingBottom: "80px" }}
					className="home-subtitle">
					RELEASING IN 2026.
				</h3>
				<div className="social-buttons-container">
					<SocialMediaButton
						platform="steam"
						text="WISHLIST ON STEAM"
						link="https://store.steampowered.com/app/2707670/SCP_Valravn/"
					/>
					<SocialMediaButton
						platform="discord"
						text="JOIN THE DISCORD"
						link="https://discord.gg/scpvalravn"
					/>
				</div>

				<div id="home-down-arrow" style={{ opacity: scrollOpacity }} onClick={handleArrowClick}>
					<FaAngleDown size={50} />
				</div>

				<div id='home-video-link'>
					<a href="https://youtu.be/rfezrcMo3H4" target="_blank" rel="noopener noreferrer">
						<FaPlay style={{paddingBottom: "4px"}}/> WATCH TRAILER
					</a>
				</div>
			</section>

			<section id="games">
				<div className="section-header">
					<img
						src={studio_icon}
						alt=""
						className="section-header-icon"
					/>
					<h2>OUR GAMES</h2>
				</div>
				<div className="section-header" id="section-header-games" style={{ gap: "50px" }}>
					<img
						src={game_logo}
						alt=""
						className="game-logo"
					/>
					<div>
						<h3 style={{ fontSize: "1.6rem", paddingBottom: "10px" }}>
							SCP: Valravn releases in 2026.
						</h3>
						<p className="game-description">
							<b>Trepang2</b> meets <b>Alien: Isolation</b> in the first
							commercial video game based on the popular <b>SCP Foundation</b>{" "}
							universe. You are Leena Sorjova—an elite combat operative of the
							Global Occult Coalition, faced with a mission to save your father
							from Valravn Corporation from horrors beyond human comprehension
							unleashed upon the facility he works in.
						</p>
						<div
							className="social-buttons-container"
							id="game-socials"
							style={{ justifyContent: "left" }}
						>
							<SocialMediaButton
								platform="steam"
								link="https://store.steampowered.com/app/2707670/SCP_Valravn/"
							/>
							<SocialMediaButton
								platform="discord"
								link="https://discord.gg/scpvalravn"
							/>
							<SocialMediaButton
								platform="youtube"
								text="Gameplay Trailer"
								link="https://youtu.be/rfezrcMo3H4"
							/>
						</div>
					</div>
				</div>
				<div style={{ paddingTop: "5vh" }}>
					<ImageCarousel images={[screenshot1, screenshot2, screenshot3]} />
				</div>
			</section>

			<section id="company">
				<div className="section-header">
					<img
						src={studio_icon}
						alt=""
						className="section-header-icon"
					/>
					<h2>OUR COMPANY</h2>
				</div>
				<div className="section-content">
					<h3>ABOUT US</h3>
					<p>
						Subtle Bravado Studios is a company founded in Croatia by a
						passionate game developer... Lorem ipsum dolor sit amet, consectetur
						adipiscing elit. Fusce nec metus ultricies erat tincidunt dapibus.
						Sed faucibus justo efficitur, fermentum urna in, sollicitudin
						lectus. Nullam ac nisi eget magna faucibus egestas. Nulla enim
						lectus, viverra in nibh et, consectetur tempor massa. Etiam
						tincidunt nunc at erat pharetra, nec elementum nulla iaculis.
						Integer ut ante gravida, auctor mauris sed, ultricies nisi. Etiam
						elit sapien, tincidunt sed velit in, pellentesque blandit lorem.
						Quisque id sodales sapien. Morbi sed dui in ex imperdiet aliquam sed
						eu erat. Cras eu odio eget est molestie pellentesque a ac odio.
						Phasellus sed suscipit diam. Sed vel mauris ac metus pretium
						faucibus ac non erat. In nisl ligula, placerat eu volutpat
						tincidunt, tempus sed ipsum. Fusce augue libero, congue vel egestas
						ut, vulputate eget eros. Phasellus non dolor molestie, iaculis leo
						vitae, pretium tellus.
						<br />
						<br />
						Morbi ut magna eget erat aliquam porta. Donec sed vehicula justo.
						Cras in massa vel metus aliquam volutpat sed tempor dolor. Proin
						tempor, dolor vel facilisis facilisis, arcu turpis aliquam magna,
						quis scelerisque nisi eros eu elit. Donec ultricies ex odio, sed
						efficitur libero cursus at. Sed sed ex ornare, porttitor massa sit
						amet, lacinia velit. Suspendisse ac odio at nulla bibendum
						imperdiet. Integer eleifend imperdiet lorem, sit amet convallis
						metus convallis auctor. Maecenas ultricies est non dui accumsan,
						vitae interdum est malesuada. Vestibulum lacinia, mauris nec finibus
						cursus, felis ante porttitor risus, at mattis libero felis vitae
						tortor. Proin sed lorem in quam placerat mollis et non nibh. Ut
						lorem ex, elementum nec dui et, porttitor volutpat est.
					</p>
					<div className="section-separator"></div>
					<div className="social-icons-container">
						<a
							href="https://x.com/NikkF__"
							aria-label="Twitter"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaXTwitter />
						</a>
						<a
							href="https://discord.gg/scpvalravn"
							aria-label="Discord"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaDiscord />
						</a>
						<a
							href="https://www.youtube.com/@SubtleBravadoStudios"
							aria-label="YouTube"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaYoutube />
						</a>
						<a
							href="https://store.steampowered.com/app/2707670/SCP_Valravn/"
							aria-label="Steam"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaSteam />
						</a>
					</div>
				</div>
			</section>

			<section id="team">
				<div className="section-header">
					<img
						src={studio_icon}
						alt=""
						className="section-header-icon"
					/>
					<h2>OUR TEAM</h2>
				</div>

				<div className="team-main-member">
					{teamMembers.length > 0 && (
						<TeamMember
							icon={teamMembers[0].icon}
							fullname={teamMembers[0].fullname}
							role={teamMembers[0].role}
							socialLinks={teamMembers[0].socialLinks}
						/>
					)}
				</div>

				<div className="team-grid">
					{teamMembers.slice(1).map((member, index) => (
						<TeamMember
							key={index}
							icon={member.icon}
							fullname={member.fullname}
							role={member.role}
							socialLinks={member.socialLinks}
						/>
					))}
				</div>
			</section>

			<section id="contact" style={{ minHeight: "60vh" }}>
				<div className="section-header">
					<img
						src={studio_icon}
						alt=""
						className="section-header-icon"
					/>
					<h2>CONTACT US</h2>
				</div>

				<ContactForm />
			</section>

			<Footer />
		</div>
	);
}

export default App;
